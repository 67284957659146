import { env } from '@dx-ui/framework-env';

export const baseUrl = env('HILTON_ASSETS_URI');

export const defaultPhoneNumbers = {
  number: '8556721138',
  friendlyNumber: '855-672-1138',
};

export const HotelImageVariantSize = {
  Md: 'md',
  Sm: 'sm',
  Xs: 'xs',
} as const;

export const HotelPage = {
  HOME: 'home',
  GALLERY: 'gallery',
  HOTELINFO: 'hotel-info',
  ROOMS: 'rooms',
  DINING: 'dining',
  LOCATION: 'hotel-location',
  MEETINGSEVENTS: 'events',
  SPA: 'spa',
  GOLF: 'golf',
  EXPLORE: 'explore',
  ACTIVITIES: 'activities',
  OFFERS: 'offers',
} as const;

export const HotelGolfFeature = {
  cartRental: 'cartRental',
  instruction: 'instruction',
  practiceFacilities: 'practiceFacilities',
  proShop: 'proShop',
} as const;

export const appVersion = env('APP_VER', 'dx-property-ui:0');

export const disableMetricsCookie = 'OHW_PROP_disable_metrics';

export const goUserTypes = ['TMH', 'TPH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];

export const assetPrefixSVG = `${baseUrl}/modules/assets/svgs`;

export const autocompleteUrl = env('AUTOCOMPLETE_URL');

export const facebookAppId = '253862148111843';

type IAppConfig = {
  DEFAULT_BRAND_CODE: string;
  DEFAULT_BRAND_NAME: string;
  DISABLE_METRICS_COOKIE: string;
};

export const config: IAppConfig = {
  DEFAULT_BRAND_CODE: 'HH',
  DEFAULT_BRAND_NAME: 'Hilton',
  DISABLE_METRICS_COOKIE: 'OHW_PROP_disable_metrics',
};

export const grayBlurDataUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88h8AAs0B5QWO2loAAAAASUVORK5CYII=';

export const GLOBAL_NAMESPACES = [
  'accessibility-grid',
  'alerts',
  'common',
  'map',
  'dx-search',
  'events',
  'hotel-policies',
  'dx-utility-rail',
  'osc-alert',
  'osc-breadcrumbs',
  'osc-calendar',
  'osc-carousel',
  'osc-chip',
  'osc-date-picker',
  'osc-dialog',
  'osc-footer',
  'osc-form',
  'osc-header',
  'osc-info-popup',
  'osc-language-selector',
  'osc-link',
  'osc-location',
  'osc-pagination',
  'osc-rooms',
  'osc-shop-form',
  'osc-speedbump',
  'osc-special-rates',
  'osc-tooltip',
  'trip-advisor',
  'osc-join-form-form',
  'osc-join-form-join',
  'osc-join-form-join-button',
];

export const DayOfTheWeek = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THR: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
} as const;

export const PROPERTY_DATA_QUERY_KEY = ['propertyData'] as const;

export const EARLY_DEPARTURE_FEE = { FIXED: 'fixed', PERCENTAGE: 'percentage' } as const;

/**
 * Return `DO_NOT_RENDER` from the component data mapper when you do not want a
 * component to render on the page.
 */
export const DO_NOT_RENDER = { DO_NOT_RENDER: true } as const;

/** Environment used for property configuration. */
export const CONFIG_ENV = {
  DEV: 'dev',
  PROD: 'prod',
} as const;

export const PROPERTY_TEMPLATE = {
  INSPIRED: 'inspired',
  TAILORED: 'tailored',
} as const;

export const CUSTOMER_SERVICE_URL = 'https://help.hilton.com/s/';

export const RESERVATION_URL = 'https://www.hilton.com/en/book/reservation/find/';
