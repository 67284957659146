export const GOALS = {
  EDIT_PROPERTY_ROOMS_PAGE_SEARCH_WIDGET: 'g-hsC9M5lG1Y',
  EDIT_PROPERTY_SEARCH_WIDGET: 'edit-property-search-widget',
  EDIT_SEARCH_WIDGET: 'g-8xd5mdahgj',
  ENGAGE_CHECK_RATES_CTA: 'EngageCheckRatesCTA',
  ENGAGE_PROPERTY_QUICKBOOK_CTA: 'engage-property-quickbook-cta',
  GALLERY_IMAGE_VIEWS_SINGLE: 'g-gallery-image-views-single-count',
  GALLERY_IMAGE_VIEWS_TOTAL: 'g-gallery-image-views-total',
  GALLERY_VIEW_ROOMS_CTA: 'g-gallery-view-rooms-cta-click',
  MORE_PRICES_AVAILABLE_CTA: 'g-MorePricesAvailableCTA',
  PROPERTY_ROOMS_TAB_PAGE_LOAD: 'property-rooms-tab-page-load',
  ROOMS_AND_SUITES_ENGAGEMENT: 'g-rooms-and-suites-section-engagement',
  SUCCESSFUL_LOGIN_FROM_PROPERTY: 'successful-login-from-property',
  VIDEO_CLICK_CTA: 'g-9-16-video-cta-click',
  VIEW_ALL_ROOMS_CTA: 'g-view-all-rooms-cta',
  ENGAGE_VIRTUAL_TOUR_MODAL_CTA: 'g-engage-virtual-tour-in-modal-cta',
};
