/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
/**
 * The AdobeTagManager is used in the application to fire analytics / metrics events.
 *
 * @file   This files defines the AdobeTagManager class.
 * @author Bryan Terrell.
 */
import { AdobeTagManager } from '@dx-ui/config-metrics';
import { appVersion } from '../constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type {
  DefaultPageDataProps,
  TErrorPageDataProps,
  TPageDetailsProps,
  TTrackGenericAction,
  TTextHeadlinerProps,
  TBrandShowcaseProps,
  TCarouselNavigationProps,
  TFullWidthImageProps,
  TGridItemProps,
  TTabItemProps,
  AlertDataLinkProps,
  AlertDataProps,
  TUserDataProps,
  TTrackPolicies,
  TPageNameProps,
} from '../metrics.types';
import { defaultPageData } from './constants';

const capitalize = (s: string) => s && s.charAt(0).toUpperCase() + s.slice(1);

const getBrand = ({
  brandCode: receivedBrandCode,
  brandName: receivedBrandName,
}: {
  brandCode: string;
  brandName: string;
}) => {
  const hiltonName = 'Hilton';
  let isHiltonPortfolio = false;
  let brandCode = receivedBrandCode;
  let brandName = receivedBrandName;

  // need to override Hilton Portfolio to use Hilton values for analytics
  if (brandCode === 'WW') {
    isHiltonPortfolio = true;
    brandCode = 'HI';
    brandName = hiltonName;
  }
  brandName = brandName || hiltonName;

  return { brandCode, brandName, isHiltonPortfolio };
};

const getPageName = (pageProps: TPageNameProps) => {
  const language = pageProps.lang?.toUpperCase();
  const pageName = pageProps.pageName || defaultPageData.pageName;
  const brandName = pageProps.brandName || defaultPageData.brandName;
  const primaryCategory = pageProps.primaryCategory || defaultPageData.primaryCategory;
  return `Browser:${language}:${brandName}:${primaryCategory}:${capitalize(pageName)}`;
};

class ExtendedAdobeTagManager extends AdobeTagManager {
  private mergedProps!: DefaultPageDataProps;

  public setDefaultPageData(props: DefaultPageDataProps) {
    this.mergedProps = {
      ...defaultPageData,
      ...(this.mergedProps || {}),
      ...props,
    };
    this.setDataLayer();
  }

  public trackEvent(eventName: string, params: unknown = null, shouldAddEvent = false) {
    void this['track'](eventName, params, shouldAddEvent);
    if (!shouldAddEvent) {
      this['_resetEvents']();
    }
  }

  public trackPolicies({ iconText, position, total = 0 }: TTrackPolicies) {
    this.setDataLayer();
    this['_set']('page.attributes.actionDetail', `Tab:Property:Hotel Policies:${iconText}`);
    this['_set']('page.pageInfo.gridPosition', `${position}:${total}`);
    this.trackEvent('propertyPoliciesTabs', null, true);
  }

  public trackElemClickId(clickID: string) {
    this._set('click.clickID', clickID);
  }

  public trackAccessibleAmenities() {
    this.setDataLayer();
    this['_set']('page.attributes.actionDetail', 'Modal:Accessible Amenities');
    this.trackEvent('propertyAccessibleAmenitiesModal', null, true);
  }

  private trackCarousel(
    eventName: string,
    actionDetail: string,
    currentSlide: number,
    totalSlides: number
  ) {
    this.setDataLayer();
    this['_set']('page.attributes.actionDetail', actionDetail);
    this['_set']('page.attributes.imageCarouselNumber', `${currentSlide}:${totalSlides}`);
    this.trackEvent(eventName, null, true);
  }

  public trackFullCarousel({
    eventName = 'propertyTopCarousel',
    actionDetail = 'Carousel:Property Top Carousel',
    currentSlide = 0,
    totalSlides = 0,
  }) {
    this.trackCarousel(eventName, actionDetail, currentSlide, totalSlides);
  }

  public trackRoomsGridCarousel(currentSlide: number, totalSlides: number) {
    this.trackCarousel(
      'propertyRoomDetailsCarousel',
      'Carousel:Property Room Details Carousel',
      currentSlide,
      totalSlides
    );
  }

  setPageData() {
    this.mergedProps = {
      ...defaultPageData,
      ...(this.mergedProps || {}),
    };
    return this.mergedProps;
  }

  public trackUserLoggedIn({ hhonorsNumber, tierName, points, goUserTypes }: TUserDataProps) {
    if (hhonorsNumber) {
      this['_set']('user[0].profile[0].profileInfo.profileID', hhonorsNumber);
      this['_set']('user[0].profile[0].profileInfo.pointsBalance', points);
      this['_set']('user[0].profile[0].profileInfo.rewardsTier', tierName);
      this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-in');

      if (goUserTypes && goUserTypes.length) {
        this['_set']('user[0].profile[0].attributes.goUserType', goUserTypes.join(','));
      } else {
        this['_set']('user[0].profile[0].attributes.goUserType', '');
      }
    } else {
      this['_set']('user[0].profile[0].profileInfo.profileID', '');
      this['_set']('user[0].profile[0].profileInfo.pointsBalance', '');
      this['_set']('user[0].profile[0].profileInfo.rewardsTier', '');
      this['_set']('user[0].profile[0].attributes.goUserType', '');
      this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-out');
    }

    this.trackEvent('trackUserLoggedIn', null, true);
  }

  public setPageInfo(key: string, value: string[] | string) {
    this['_set'](`page.pageInfo.${key}`, Array.isArray(value) ? value.join(',') : value);
  }

  public setPageDetails({ pageDetail, detailIdx = 1 }: TPageDetailsProps) {
    this['_set'](`page.pageInfo.pageDetail${detailIdx}`, pageDetail);
  }

  public setAttributes(valuesByAttributes: { [key: string]: string }) {
    const attributes = Object.keys(valuesByAttributes);
    attributes.forEach((attribute) => {
      this['_set'](attribute, valuesByAttributes[attribute]);
    });
  }

  private isBrandPage() {
    return !this.mergedProps.ctyhocn;
  }

  public trackPageView(pageView: string) {
    this['_resetEvents']();
    this.trackEvent(pageView, null, true);
  }

  public trackRoomsPageView() {
    this.trackEvent('roomsPageView', null, true);
  }

  public trackTextHeadlinerClick({
    textHeadlinerNumber,
    itemNumber,
    totalItems,
    leftArrow,
    rightArrow,
  }: TTextHeadlinerProps) {
    this['_set'](
      'page.attributes.actionDetail',
      `Carousel:${
        this.isBrandPage() ? 'Brand' : 'Property'
      }:TextHeadliner:Set:${textHeadlinerNumber}:${
        leftArrow ? 'Leftarrow' : rightArrow ? 'Rightarrow' : ''
      }`
    );
    this['_set']('page.pageInfo.gridPosition', `${itemNumber}:${totalItems}`);
    this['_set']('event.eventInfo.eventAction', 'textHeadlinerClick');

    this.trackEvent('textHeadliner', null, true);
  }

  trackBrandShowcaseClick({
    brandShowcaseNumber,
    itemNumber,
    totalItems,
    brandName,
  }: TBrandShowcaseProps) {
    this.setAttributes({
      'page.attributes.actionDetail': `Tile:Brand:Brandshowcase:${brandShowcaseNumber}:${brandName}`,
      'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
      'event.eventInfo.eventAction': 'BrandShowcaseClick',
    });
    this.trackEvent('brandShowcase', null, true);
  }

  trackCarouselNavigationClick({
    rightArrow,
    leftArrow,
    carouselNumber,
    index,
    totalDots,
  }: TCarouselNavigationProps) {
    const label = rightArrow ? 'Rightarrow' : leftArrow ? 'Leftarrow' : 'Dot';
    this.setAttributes({
      'page.attributes.actionDetail': `Carousel:Brand:ImageCarousel:${carouselNumber}:${label}`,
      'page.attributes.imageCarouselNumber': `${index + 1}:${totalDots}`,
      'event.eventInfo.eventAction': 'brandCarouselClick',
    });
    this.trackEvent('brandCarousel', null, true);
  }

  trackFullWidthImageClick({ fullWidthImageNumber, label }: TFullWidthImageProps) {
    this.setAttributes({
      'page.attributes.actionDetail': `Tile:Brand:FullWidthImage:${fullWidthImageNumber}:${label}`,
      'page.pageInfo.gridPosition': '',
      'event.eventInfo.eventAction': 'BrandFullWidthImageClick',
    });
    this.trackEvent('brandFullWidthImage', null, true);
  }

  trackGridItemClick({ itemNumber, totalItems, label, gridNumber, is4XGrid }: TGridItemProps) {
    if (is4XGrid) {
      this.setAttributes({
        'page.attributes.actionDetail': `Tile:Brand:4XImage:${gridNumber}:${label}`,
        'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
        'event.eventInfo.eventAction': 'Brand4XTileClick',
      });
      this.trackEvent('brand4XTile', null, true);
    } else {
      this.setAttributes({
        'page.attributes.actionDetail': `Tile:Brand:369Image:${gridNumber}:${label}`,
        'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
        'event.eventInfo.eventAction': 'Brand369TileClick',
      });
      this.trackEvent('brand369Tile', null, true);
    }
  }

  trackTabItemClick({ label, tabComponentNumber, itemNumber, totalItems }: TTabItemProps) {
    this.setAttributes({
      'page.attributes.actionDetail': `Tab:Brand:${tabComponentNumber}:${label}`,
      'page.pageInfo.gridPosition': `${itemNumber}:${totalItems}`,
      'event.eventInfo.eventAction': 'brandTabClick',
    });
    this.trackEvent('brandTab', null, true);
  }

  trackAlertLinkClick({ text, linkLabel }: AlertDataLinkProps) {
    this.setAttributes({
      'page.attributes.actionDetail': `Modal:Brand:Alert:${text}:${linkLabel}`,
      'event.eventInfo.eventAction': 'brandAlertModalLink',
    });
    this.trackEvent('brandAlertModalLink', null, true);
  }

  trackAlertClick({ text }: AlertDataProps) {
    this.setAttributes({
      'page.attributes.actionDetail': `Modal:Brand:Alert:${text}`,
      'event.eventInfo.eventAction': 'brandAlertModal',
    });
    this.trackEvent('brandAlertModal', null, true);
  }

  /**
   * @method trackGenericAction
   * @param {string} eventName - defines the name of the event given to Adobe Tag Manager.
   * @param {string} actionDetail - gives more information about the event.
   *
   * Available to set common pieces of data used between analytics functions
   *
   */
  public trackGenericAction({ eventName = '', actionDetail = '' }: TTrackGenericAction) {
    this.setDataLayer();
    this['_set']('page.attributes.actionDetail', actionDetail);
    this.trackEvent(eventName, null, true);
  }

  public setErrorPageData(props: TErrorPageDataProps) {
    const mergedProps = { ...defaultPageData, ...props };
    const { brandCode } = mergedProps;
    const { errorCode } = props;
    let { errorName } = props;

    errorName = errorName || '';

    this['_set']('event.eventInfo.eventAction', 'error');
    this['_set']('page.category.primaryCategory', 'error');
    this['_set']('page.category.subSection', `error:${errorName}`);
    this['_set']('page.pageInfo.error', errorCode);
    this['_set']('page.pageInfo.pageName', `${brandCode}:error:${errorName}`);

    this.trackEvent('errorPageView', null, false);
    this.trackEvent('errorEvent', null, false);
  }

  /**
   *
   * @method _addPageBottom
   * Overriding _addPageBottom to prevent running server side
   */
  // eslint-disable-next-line
  public _addPageBottom() {
    if (isBrowser && document && document.body) {
      const { body } = document;
      const script = document.createElement('script');
      // Lets add to page so Adobe consultant knows we've added the pageBottom() call.
      const scriptContent = `
         "use strict";
         var _satellite = window._satellite;
         if (_satellite) {
           document.write = document.body.appendChild
           _satellite.pageBottom();
         }
       `;
      script.text = scriptContent;
      return body.appendChild(script);
    }
  }

  private setDataLayer = () => {
    const {
      ctyhocn = '',
      country,
      lang,
      template = '',
      pageName,
      expType,
      pageType,
      pageDetail,
      subSection,
      destinationUrl,
      subSubSection = '',
      experience,
    } = this.mergedProps;
    const { brandCode, brandName } = getBrand(this.mergedProps);
    const formattedPageName =
      (pageName &&
        (capitalize(pageName) === 'Events' ? 'Meetings-events' : capitalize(pageName))) ||
      'Home';
    let { primaryCategory } = this.mergedProps;
    primaryCategory = capitalize(primaryCategory || '') || formattedPageName;
    this['_set']('page.attributes.expTheme', capitalize(template));
    this['_set']('page.attributes.propertySearchCountry', country);
    this['_set']('page.attributes.version', appVersion);

    this['_set']('page.category.brand', brandCode);
    this['_set']('page.category.siteName', brandName);
    this['_set']('page.category.primaryCategory', primaryCategory);
    this['_set']('page.category.exp', experience);
    if (!this.isBrandPage()) {
      this['_set']('page.category.subSection', subSection || `property:${formattedPageName}`);
      this['_set']('page.category.subSubSection', subSubSection);
      this['_set']('product[0].productInfo.productID', ctyhocn);
    } else {
      this['_set']('page.attributes.expType', expType);
    }
    this._set(
      'page.pageInfo.destinationURL',
      destinationUrl || `${document.location.origin}${document.location.pathname}`
    );
    this['_set']('page.pageInfo.language', lang.toLowerCase());
    this['_set']('product[0].productInfo.productID', ctyhocn);
    this['_set']('page.pageInfo.pageDetail1', pageDetail ? pageDetail[0] : '');
    this['_set']('page.pageInfo.pageDetail2', pageDetail ? pageDetail[1] : '');
    this['_set']('page.pageInfo.pageDetail3', pageDetail ? pageDetail[2] : '');
    this['_set'](
      'page.pageInfo.pageName',
      getPageName({
        lang,
        brandName,
        primaryCategory,
        pageName,
      })
    );
    this['_set']('page.pageInfo.pageTitle', document.title);
    this['_set']('page.pageInfo.pageType', pageType || formattedPageName);
  };
}

export default ExtendedAdobeTagManager;
