import type { ValuesOf } from './types';

export type THomePageComponents = ValuesOf<typeof homePageComponents>;

export const homePageComponents = {
  CELEBRITY_QUOTE: 'celebrityQuote',
  DESCRIPTION_AMENITIES: 'descriptionAmenities',
  EVENTS_INFO: 'eventsInfo',
  FULL_WIDTH_DYNAMIC_HERO: 'fullWidthDynamicHero',
  FULL_WIDTH_IMAGE_CAROUSEL: 'fullWidthImageCarousel',
  FULL_WIDTH_VIDEO: 'fullWidthVideo',
  HALFANDHALF_CAROUSEL: 'halfAndHalfCarousel',
  HONORS: 'honors',
  HOTEL_DESCRIPTION: 'hotelDescription',
  HOTEL_POLICIES: 'hotelPolicies',
  INSPIRED_HERO: 'inspiredHero',
  LOCATION_AND_TRANSPORTATION: 'locationAndTransportation',
  PARTNERSHIP_INFO: 'partnershipInfo',
  PATCHWORK_GRIDS: 'patchworkGrids',
  PROPERTY_ALERTS: 'propertyAlerts',
  ROOMS_OVERVIEW: 'roomsOverview',
  STAY_TOUR_VIDEO: 'stayTourVideo',
  STICKY_SIDE_BY_SIDE: 'stickySideBySide',
  TABBED_OVERVIEW: 'tabbedOverview',
  UTILITY_RAIL: 'utilityRail',
  VERTICAL_POLICY_TABS: 'verticalPolicyTabs',
  VOUCHER_INFO: 'voucherInfo',
} as const;
